import React from 'react';
import styled from 'styled-components';

const ListItem = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  p {
    max-width: calc(100% - 75px);
    margin: 0;
    text-align: left;
    font-size: calc(14px + 0.2vw);
  }
`;

const ItemNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background: #fff;
  border-radius: 50%;
  border: 4px solid #4aa35b;
  color: #000;
`;

const ListItemContainer = ({ items }) =>
  items.map((item, itemIndex) => (
    <ListItem key={itemIndex}>
      <ItemNumber>{itemIndex + 1}</ItemNumber>
      <p>{item}</p>
    </ListItem>
  ));

export default ListItemContainer;
