import React from 'react';
import styled from 'styled-components';
import ListItemContainer from '../../components/ThankyouPages/ListItemContainer';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

// prettier-ignore
// const adIds = ['483835', '483836', '483837', '483838', '483839', '483840', '483841', '483842'];

const CalContainer = styled.div`
  text-align: center;

  h1 {
    font-size: 22px;
    font-weight: 400;
  }

  img {
    width: 90%;
    max-width: 450px;
    margin: 20px auto;
  }
`;

const SubSection = styled.section`
  width: calc(96% - 40px);
  max-width: 660px;
  padding: 10px 20px 10px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 3px 8px #555;
  margin-bottom: 10px;

  background: ${({ background }) => background && background};
  border: ${({ border }) => border && border};
  color: ${({ color }) => color && color};

  h2 {
    text-align: center;
    padding: 0;
    margin: 0 0 10px 0;
    line-height: 1;
  }
`;

const sectionOne = [
  'Caliber Home Loans offer a growing range of home loan and refinancing options including FHA, VA and USDA loans, jumbo loans up to $3 million and financing for new builds, second homes and investment properties.',
  'We’re equipped and committed to addressing your concerns, priorities and goals throughout the entire loan process … a commitment that resonates throughout all of Caliber.',
  'We are a lender who provides fulfilling, affordable home financing. Our current business rankings are proof of this, as we’ve grown to become a leading mortgage lender in just under three years.',
];

const sectionTwo = [
  'Can I expect a lower monthly payment?',
  'Will I close within 30 days?',
  'Can I manage my mortgage online?',
  'Should I switch to a 15-year fixed?',
  'Will I get updates that will keep my informed?',
];

const caliberHomeImgSrc =
  'https://s3.amazonaws.com/sc-dba.com/cal-ty/images/caliber-home-loans-logo.png';

const CalThankyou = ({
  pageContext: {
    title = 'Caliber Home Loans',
    firstListArr = sectionOne,
    secondListArr = sectionTwo,
    imgSrc = caliberHomeImgSrc,
  },
}) => (
  <CalContainer>
    <h1>{`You've been matched with our featured provider, ${title}`}</h1>
    <img src={imgSrc} alt="Caliber Homes" />

    <SubSection background="#a9aeb2" border="4px solid #1c3d5c" color="#000">
      <h2>{`More about ${title}`}</h2>
      <ListItemContainer items={firstListArr} />
    </SubSection>
    <SubSection background="#144075" border="4px solid #fff" color="#fff">
      <h2>{`5 Questions ${title} Can Answer For You:`}</h2>
      <ListItemContainer items={secondListArr} />
    </SubSection>
    {/* <AdButler adButlerIds={adIds} /> */}
  </CalContainer>
);

export default withThankyouWrapper(CalThankyou);
